import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const wslrTheme = createMuiTheme({
  type: 'wslrTheme',
  palette: {
    primary: {
      main: '#00ffea',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: "Overpass"
  },
});

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <MuiThemeProvider theme={wslrTheme}>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
serviceWorker.unregister();
