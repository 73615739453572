import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <p>
      WSLR WindowSeller by engine-productions GmbH &nbsp;&nbsp;&nbsp; 
      <Link to={`/support`}>
        Support FAQ
      </Link> <a href="https://www.windowseller.io/impressum" target="_blank" rel="noopener noreferrer">
        Impressum
      </a>
    </p>
  </footer>
);

export default Footer;
