import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { getConfig } from "../config";

import { Alert } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Paypal from "./Paypal"

export const ProfileComponent = () => {
  const { apiOrigin } = getConfig();

  const { 
    user,
    getAccessTokenSilently, 
  } = useAuth0();
  const debug = false;

  const mount = () => {
    console.log('SETTINGS mounted')
    getUser();
  
    const unmount = () => {
      console.log('SETTINGS unmounted')
    }
    return unmount;
  }
  React.useEffect(mount, []);

  const saveProfile = async () => {
    console.log("saveProfile");
    var address = {
      'company': document.querySelector('input[name="company"]').value,
      'name': document.querySelector('input[name="name"]').value,
      'address': document.querySelector('input[name="address"]').value,
      'zip': document.querySelector('input[name="zip"]').value,
      'city': document.querySelector('input[name="city"]').value,
    }
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'updateUser',
          address: JSON.stringify(address),
          name: user.name,
          email: user.email
        })
      });
      const responseData = await response.json();
      setState({
        ...state,
        userDataChanged: false,
        confirm: "userdata_saved"
      });
    } catch (error) {
      console.log("CATCHED ERROR:", error);
      setState({
        ...state,
        error: error.error,
      });
    }
  }

  const getUser = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'getUser'
        })
      });
      const responseData = await response.json();

      var as = "inactive";
      var asm = 'Kein Abonnement gefunden';
      if('undefined' !== typeof responseData.userData.brand && responseData.userData.brand.paymentId && responseData.userData.brand.paymentId !== '') {
        asm = "Abonnement abgelaufen";
        if(responseData.userData.payment.nextPayment) {
          if(responseData.userData.payment.nextPayment === "0000-00-00 00:00:00") {
            asm = "Abonnement erfolgreich angelegt";
            as = "active";
          } else {
            asm = "Abonnement aktiv (bis " + responseData.userData.payment.nextPayment + ")";
            as = "active";  
          }
        }
      }
      if(responseData.userData.address !== "") {
        var adr = JSON.parse(responseData.userData.address);
        if(adr) {
          document.querySelector('input[name="company"]').value = adr.company;
          document.querySelector('input[name="name"]').value = adr.name;
          document.querySelector('input[name="address"]').value = adr.address;
          document.querySelector('input[name="zip"]').value = adr.zip;
          document.querySelector('input[name="city"]').value = adr.city;  
        }
      }
      if(responseData.userData.name === null && responseData.userData.email === null) {
        saveProfile();
      }
      setState({
        ...state,
        userData: responseData,
        aboStatus: as,
        aboStatusMessage: asm,
        wslrBrand: ('undefined' !== typeof responseData.userData.brand) ? responseData.userData.brand.bid : ''
      });
    } catch (error) {
      console.log("CATCHED ERROR:", error);
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const [state, setState] = useState({
    aboStatus: 'inactive',
    aboStatusMessage: 'unbekannt',
    userDataChanged: false,
    userData: {},
    error: null,
    wslrBrand: '',
    confirm: '',
  });

  return (
    <>
      <Container className="mb-5">
        <Row className="align-items-center profile-header mb-5 text-center text-md-left">
          <Col md={2}>
            <img
              src={user.picture}
              alt="Profile"
              className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
            />
          </Col>
          <Col md>
            <h2>{user.name}</h2>
            <p className="lead text-muted">{user.email}</p>
          </Col>
        </Row>
        <Row>
          {debug && (<Highlight>{JSON.stringify(user, null, 2)}</Highlight>)}
          </Row>
        <Row style={{'display': 'block'}}>
          <h3>Meine Rechnungsdaten</h3>
          {state.confirm === "userdata_saved" && (
            <Alert color="success">
              Rechnungsdaten erfolgreich gespeichert.
            </Alert>
          )}
          <p>Bitte hinterlegen Sie hier Ihre Daten, die Sie auf der Abrechnung vorfinden möchten.</p>
        </Row>
        <Row>
          <Col md={2}>
            <b>Firma</b>
          </Col>
          <Col md>
            <input className="mb-3" name="company" placeholder="Firma oder Geschäftsname" onChange={() => { setState({...state, userDataChanged: true}); }}></input>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <b>Ansprechpartner</b>
          </Col>
          <Col md>
            <input className="mb-3" name="name" placeholder="Vorname und Name" onChange={() => { setState({...state, userDataChanged: true}); }}></input>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <b>Adresse</b>
          </Col>
          <Col md>
            <input className="mb-3" name="address" placeholder="Strasse und Hausnummer" onChange={() => { setState({...state, userDataChanged: true}); }}></input>
          </Col>
        </Row>        
        <Row>
          <Col md={2}>
            <b>PLZ, Ort</b>
          </Col>
          <Col md={2}>
            <input className="mb-3" name="zip" placeholder="PLZ" onChange={() => { setState({...state, userDataChanged: true}); }}></input>
          </Col>
          <Col md>
            <input className="mb-3" name="city" placeholder="Ort" onChange={() => { setState({...state, userDataChanged: true}); }}></input>
          </Col>
        </Row>
        <Row className="centered" style={{'display': 'block'}}>
          <Button
            color="primary"
            onClick={saveProfile}
            className="mt-3"
            disabled={!state.userDataChanged}
          >
            Daten aktualisieren
          </Button>
        </Row>
      </Container>

      { state.wslrBrand !== '' && (
          <>
          <h3 className="mt-5">Mein WSLR-Abonnement:</h3>
          <p><b>Status: </b> <span className={state.aboStatus !== 'active' ? 'red' : 'green'}>{state.aboStatusMessage}</span></p>
          {(state.aboStatus !== 'active') &&
            (
              <>
                <p>Schließen Sie jetzt Ihr WindowSeller Abo für nur <span className="text-highlight"><b>29,99 € netto</b> pro Monat (35,68 € inkl. 19% MwSt.)</span> ab. Wir verlängern das Abo automatisch nach Ablauf für jeweils einen weiteren Monat. Sie können das Abonnement jederzeit wieder kündigen.</p>
                <div className="centered mt-5 mb-5">
                  <Paypal
                    brand={state.wslrBrand}
                  />
                </div>
              </>
            )
          }
          {(state.aboStatus === 'active') &&
            (
              <>
                <p className="hint mb-5">Sie können Ihr Abonnement jederzeit wieder kündigen. Besuchen Sie dazu Ihren PayPal Account und deaktivieren Sie dort das laufende WindowSeller Abo.</p>
              </>
            )
          }
          </>
        )
      }
      { state.wslrBrand === '' && (
        <>
          <h3><span className="text-highlight">Starten Sie jetzt mit Ihrem WindowSeller!</span></h3>
          <center>
            <Button
                color="primary"
                className="mb-5 mt-3 centered"
                onClick={() => { window.location.href="/setup"; }}
              >
                Jetzt Einrichtung starten
            </Button>    
          </center>
        </>
      )}
      <h3 className="mt-5">WSLR-Stick bestellen</h3>
      <p>Möchten Sie einen WindowSeller HDMI Stick bestellen, um diesen an Ihren Monitor oder Fernseher anzuschließen?</p>
      <p>Der Stick wird direkt für Sie vorkonfiguriert und hat eine Autostart-Funktion. Einfach einstecken und los gehts!</p>
      <p>Senden Sie uns einfach eine Bestellanfrage zusammen mit Ihrer WSLR-ID (&quot;{state.wslrBrand}&quot;) an <a href={"mailto:sales@wslr.io?subject=Bestellung%20WSLR%20Stick%20(für%20WSLR-ID%20" + state.wslrBrand + ")&body=Bitte%20geben%20Sie%20Ihre%20Liefer-%20und%20Rechnungsanschrift%20an."}>sales@wslr.io</a>.</p>
      <Button
          color="primary"
          className="mb-5 mt-3 centered"
          onClick={() => { window.location.href="mailto:sales@wslr.io?subject=Bestellung%20WSLR%20Stick%20(für%20WSLR-ID%20" + state.wslrBrand + ")&body=Bitte%20geben%20Sie%20Ihre%20Liefer-%20und%20Rechnungsanschrift%20an."; }}
        >
          Jetzt WSLR-Stick bestellen
      </Button>
   </>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
