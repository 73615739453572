import React, { useState } from "react";
import Loading from "../Loading";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import Fuse from "fuse.js";
import MuiButton from '@material-ui/core/Button';
import { Tooltip } from "@material-ui/core";


const WSLRMySlideShowData = (props) => {
  const { apiOrigin } = getConfig();

  const {
    getAccessTokenSilently,
  } = useAuth0();
  
  const handleChange = (event) => {
    toggleProduct(event.target.name, event.target.checked);
    for(var i in props.products) {
      if(props.products[i].pid === event.target.name) {
        props.products[i].active = event.target.checked ? "1" : "0"
      }
    }
  };

  const toggleProduct = async (productId, isActive) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'toggleProduct',
          brand: props.brand,
          product: productId,
          active: isActive ? "1" : "0",
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
      } else {
      }
    } catch (error) {}
  };


  const [filterSelected, setFilterSelected] = useState(false);
  const toggleFilterSelected = (e,s) => {
      setFilterSelected(s);
  }
  React.useEffect(() => {
    searchData(document.getElementsByClassName('searchinput')[0]?.value);
  }, [filterSelected]);

  const [showAll, setShowAll] = useState(false);

  const [data, setData] = useState(props.products);
  React.useEffect(() => {
    setData(props.products);
  }, [props.products]);

  const searchData = (pattern) => {
    if('undefined' === typeof pattern) return;
    if (!pattern) {
      const all = [];
      props.products.forEach((item) => {
        if(!(filterSelected && item.active === "0")) {
          all.push(item);
        }
      });
      setData(all);
      return;
    }

    const fuse = new Fuse(props.products, {
      keys: ["name"],
    });

    const result = fuse.search(pattern);
    const matches = [];
    if (!result.length) {
      setData([]);
    } else {
      result.forEach(({item}) => {
        if(!(filterSelected && item.active === "0")) {
          matches.push(item);
        }
      });
      setData(matches);
    }
  };

  return (
    <div className="wslr-myslideshowdata">
      {props.loading && (
        <Loading />
      )}
      {props.message !== "" && (
        <p className="productsMessage">{props.message}</p>
      )}
      {props.products && props.products.length > 0 && (
        <>
        <input
          className="searchinput mt-3"
          type="text"
          onChange={(e) => searchData(e.target.value)}
          placeholder="Produkte filtern..."
        />
        <FormControlLabel
          key=""
          control={<Checkbox color="primary" onChange={toggleFilterSelected} name="filterSelected" />}
          label="nur aktive Produkte zeigen"
        />
        <div className="hint mb-3">Zeige {data.length} von {props.products.length} Produkten:</div>
        {(data.length > 0) && data.map((product, i) => {     
          return (
            <Tooltip title={<div onClick={() => { window.open(product.urlOriginal); }} style={{cursor:'pointer'}}>{product.urlOriginal}</div>} enterDelay={500} interactive placement="top">
              <FormControlLabel
                key={product.pid}
                control={<Checkbox color="primary" defaultChecked={product.active === "1"} onChange={handleChange} name={product.pid} />}
                label={product.name}
                style={(i>9 && !showAll) ? {display: 'none'} : {display: 'inline-flex'}}
              />
            </Tooltip>
          )
        })}
        {(data.length > 10) &&
          <MuiButton
            style={{display:'flex'}}
            color="primary"
            onClick={() => {setShowAll(!showAll)}}
          >
            {(showAll) ? "weniger Produkte anzeigen" : "alle Produkte einblenden"}
          </MuiButton>
        }
      </>
      )}
    </div>
  );
}

export default WSLRMySlideShowData;
