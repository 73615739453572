const faqData = [
  {
    title: "Muss ich ein WindowSeller Abonnement abschließen?",
    description:
      "Um die WindowSeller Slideshow vollumfänglich nutzen zu können, müssen Sie ein aktives Abonnement haben. Sie können dieses in Ihren Profileinstellungen abschließen und auf Wunsch jederzeit wieder kündigen. Ist kein Abonnement vorhanden oder ausgelaufen, werden keine Produkte in der Slideshow angezeigt."
  },
  {
    title: "In meinem Shop werden keine Produkte gefunden.",
    description:
      "Prüfen Sie bitte, ob Sie die richtige Shopadresse eingegeben haben. Am besten nutzen Sie eine Übersichtseite als Einstiegspunkt. Sollten viele Seiten durchsucht werden, aber keine Produkte gefunden werden, melden Sie sich bitte mit der Shopadresse bei unserem Support und wir prüfen Ihren Shop gemeinsam."
  },
  {
    title: "Wo bekomme ich einen WSLR-Stick?",
    description:
      "Gehen Sie im eingeloggten Zustand in den Bereich \"Meine Daten\". Hier finden Sie ganz unten die Bestelloption für einen oder mehrere WSLR Sticks."
  }
];

export default faqData;
