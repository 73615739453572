import React from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";

const Home = () => (
  <>
    <Hero />
    <hr />
    <Content />
  </>
);

export default Home;
