import React from "react";
import { Button } from "reactstrap";

const Hero = () => (
  <div className="text-center hero my-5">
    <h1 className="mb-4">Herzlich Willkommen bei WSLR</h1>

    <p className="lead">
      Starten Sie jetzt mit Ihrem WindowSeller!<br />

      <Button
          color="primary"
          className="mt-5"
          onClick={() => { 
            if('undefined' === typeof window.wslrUserStatus) {
              window.document.getElementById("qsLoginBtn").click();
            } else {
              window.location.href=(window.wslrUserStatus === 'active') ? "/settings" : "/setup";
            }
           }}
        >
          Los geht's
      </Button>    
    </p>
  </div>
);

export default Hero;
