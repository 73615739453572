import React, { Component } from "react";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faqData from "../utils/faqData";

class Faq extends Component {
  render() {
    return (
      <>
        <h1>FAQ</h1>
        <p>Vielleicht helfen Ihnen unsere FAQ weiter? Finden Sie hier eine stetig wachsende Liste mit häufigen Userfragen und den passenden Antworten:</p>
        <Row className="d-flex justify-content-between">
          {faqData.map((col, i) => (
            <Col key={i} md={5} className="mb-4">
              <h6 className="mb-3 text-highlight">
                  <FontAwesomeIcon icon="question-circle" className="mr-2" />
                  {col.title}
              </h6>
              <p>{col.description}</p>
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

export default Faq;
