import configJson from "./auth_config.json";

export function getConfig() {
  const audience =
    configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
      ? configJson.audience
      : null;

  return {
    apiOrigin: "https://wslr.io/wslr-frontend/api/private.php",
    //apiOrigin: "http://localhost/wslr/wslr-frontend/api/private.php",
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
  };
}
