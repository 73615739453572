import React, { useState } from "react";
import { Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import Highlight from "../components/Highlight";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import WSLRProducts from "../components/WSLR/Products.js";
import WSLRMySlideShowData from "../components/WSLR/MySlideShowData";
import validator from "validator";

export const SetupComponent = () => {
  const { apiOrigin } = getConfig();

  // Steps:
  // 1: Brand ID
  // 2: Crawl URL
  // 3: Start Crawling
  // 4: Crawling Status update
  // 5: Crawling Finished Successfully, Choose Products
  // 6: Finished everything, next Steps...

  const mount = () => {
    console.log('SETUP mounted')
    restoreSettings();
  
    const unmount = () => {
      console.log('SETUP unmounted')
    }
    return unmount;
  }
  React.useEffect(mount, []);

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    wslrBrand: "",
    wslrUrl: "",
    wslrProducts: {},
    loading: false,
    step: 1,
    productsMessage: ""
  });

  const {
    getAccessTokenSilently,
    loginWithPopup,
  } = useAuth0();

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const setBrand = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'setBrand',
          brand: state.wslrBrand
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        setState({
          ...state,
          step: 2,
          error: null,
        });
        scrollToStep(2);
      } else {
        setState({
          ...state,
          error: "brand_exists",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const restoreSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'getBrand'
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        setState({
          ...state,
          step: responseData.brand.bid !== "" ? (responseData.brand.crawlUrl !== "" ? 3 : 2) : 1,
          wslrBrand: responseData.brand.bid,
          wslrUrl: responseData.brand.crawlUrl
        });
        scrollToStep(responseData.brand.bid !== "" ? (responseData.brand.crawlUrl !== "" ? 3 : 2) : 1);
      } else {
        setState({
          ...state,
          error: "no_brand_accessible",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const setUrl = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'setCrawlUrl',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        setState({
          ...state,
          step: 3,
          error: null,
        });
        scrollToStep(3);
      } else {
        setState({
          ...state,
          error: "crawl_url_not_set",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const startCrawling = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'startCrawling',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        window.wslrCountCrawlStart = 0;
        checkCrawling();
        setState({
          ...state,
          step: 4,
          error: null,
          loading: true
        });
        scrollToStep(4);
      } else {
        setState({
          ...state,
          error: "crawling_start_failed",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const endCrawling = async () => {
    try {
      const token = await getAccessTokenSilently();
      await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'endCrawling',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
    } catch (error) {}
  };

  window.wslrCrawlCheckTimeout = null;
  window.wslrCountCrawlStart = 0;
  const checkCrawling = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'checkCrawling',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        if(responseData.crawlData.status !== 'finished') {
          switch(responseData.crawlData.status) {
            case 'start':
              window.wslrCountCrawlStart++;
              // if start exists for >10 sec, an error occured; then:
              if(window.wslrCountCrawlStart>10) {
                setState({
                  ...state,
                  step: 2,
                  error: "crawling_url_wrong",
                  loading: false,
                });
                scrollToStep(2);
                endCrawling();
                return;
              }
              break;
            case 'running':
              // update counter
              var msg = "Wir haben bislang " + responseData.crawlData.pages + " Seiten durchsucht und " + responseData.crawlData.products + " Produkte gefunden...";
              setState({
                ...state,
                productsMessage: msg,
                loading: true,
                step: 4,
                error: null,
              });
              break;
            case 'error':
              setState({
                ...state,
                step: 2,
                error: "crawling_url_wrong",
                loading: false,
              });
              scrollToStep(2);
              endCrawling();
              return;
            default:
              return;
          }
          clearTimeout(window.wslrCrawlCheckTimeout);
          window.wslrCrawlCheckTimeout = setTimeout(checkCrawling, 1000);
        } else {
          // finished crawling
          endCrawling();
          if(responseData.crawlData.pages === "0") {
            // nothing found
            setState({
              ...state,
              step: 2,
              error: "crawling_url_wrong",
              loading: false,
            });
            scrollToStep(2);
            return;
          }
          if(responseData.crawlData.products === "0") {
            // no products found
            setState({
              ...state,
              step:2,
              error: "crawling_url_noproducts",
              loading: false,
            });
            scrollToStep(2);
            return;
          }
          // success
          setState({
            ...state,
            step: 5,
            error: null,
            loading: false,
          });
          scrollToStep(5);
          loadProducts();
        }
      } else {
        setState({
          ...state,
          error: "crawling_check_failed",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const loadProducts = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'loadProducts',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        setState({
          ...state,
          step: 5,
          wslrProducts: responseData.products
        });
      } else {
        setState({
          ...state,
          step: 3,
          error: "error_loading_products",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  const scrollToStep = (stp) => {
    try {
      stp = stp >= 3 && stp < 6 ? 3 : stp;
      document.getElementById("step-" + stp).scrollIntoView({
        behavior: "smooth"
      });  
    } catch(e) {}
  }

  return (
    <>
      <div className="mb-5">
        {state.error === "login_required" && (
          <Alert color="danger">
            Sie müssen sich{" "}
            <a
              href="#/"
              className="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              erneut anmelden.
            </a>
          </Alert>
        )}

        <h1>Einrichtung</h1>
        <p className="lead">
          Richten Sie WindowSeller mit Ihrem Online Shop ein.
        </p>

        <p>
          In nur drei Schritten haben Sie es geschafft und können Ihre Produkte auf einem
          Monitor Ihrer Wahl in Ihrem Schaufenster präsentieren!<br/>
          Viel Erfolg!
        </p>

        <h3 id="step-1" className="mt-5"><span className={state.step === 1 ? 'text-highlight' : ''}>1. Meine WSLR-ID</span></h3>
        <p>Geben Sie dazu zuerst Ihre Wunsch-Kennung (WSLR-ID) ein.</p>
        {state.error === "brand_exists" && (
          <Alert color="danger">
            Die von Ihnen gewählte WSLR-ID ist leider bereits vergeben.
          </Alert>
        )}
        <input
          id="wslrBrand"
          placeholder="Ihre Wunsch-ID"
          onChange={(e) => {
            e.target.value = e.target.value.replace(/[^a-z-]/,'').toLowerCase();
            setState({
              ...state,
              wslrBrand: e.target.value,
            });
          }}
          value={state.wslrBrand}
          disabled={state.step>1}></input><br/>
          {!validator.matches(state.wslrBrand, /^[a-z-]+$/) && (<><span className="hint">Bitte nur Kleinbuchstaben und außer dem Bindestrich (-) keine Sonderzeichen oder Leerzeichen benutzen.</span><br/></>)}
        <Button
          color="primary"
          className="mt-3"
          onClick={setBrand}
          disabled={!validator.matches(state.wslrBrand, /^[a-z-]+$/) || state.step>1}
        >
          Weiter
        </Button>

        <h3 id="step-2" className="mt-5"><span className={state.step === 2 ? 'text-highlight' : ''}>2. Meine Shop-URL</span></h3>
        <p>Nun benötigen wir Ihre Shop-URL
        (z.B. "https://www.mein-shop.de/shop/").</p>
        {state.error === "crawling_url_wrong" && (
        <Alert color="danger">
          Die von Ihnen angegebene URL kann von uns leider nicht gefunden werden. Bitte prüfen Sie Ihre Eingabe <a
            href={state.wslrUrl}
            className="alert-link"
            target="_blank"
            rel="noopener noreferrer"
          >{state.wslrUrl}</a>
        </Alert>
        )}
        {state.error === "crawling_url_noproducts" && (
          <Alert color="warning">
            Unter der von Ihnen angegebenen URL finden wir leider keine Produkte. Bitte prüfen Sie, ob die angegebene URL korrekt ist <a
              href={state.wslrUrl}
              className="alert-link"
              target="_blank"
              rel="noopener noreferrer"
            >{state.wslrUrl}</a>.<hr/>
            Sollte die URL korrekt sein, prüfen Sie bitte, ob Ihr Online-Shop die notwendigen Voraussetzungen erfüllt oder wenden Sie sich an unseren Support. <a
              href="/support"
              className="alert-link"
            >WSLR Hilfe aufrufen</a>
          </Alert>
          )}
        <input
          id="wslrUrl"
          placeholder="Ihre Shop-URL (z.B. https://www.mein-shop.de/shop)"
          onChange={(e) => {
            setState({
              ...state,
              wslrUrl: e.target.value,
            });
          }}
          value={state.wslrUrl}
          disabled={state.step!==2}></input><br/>{!validator.isURL(state.wslrUrl, {require_protocol: true}) && (<><span className="hint">Geben Sie bitte eine vollständige, korrekte URL an (inkl. Protokoll wie z.B. "https://")</span><br/></>)}
        <Button
          color="primary"
          className="mt-3"
          onClick={setUrl}
          disabled={!validator.isURL(state.wslrUrl, {require_protocol: true}) || state.step!==2}
        >
          Weiter
        </Button>

        <h3 id="step-3" className="mt-5"><span className={state.step >= 3 && state.step < 6 ? 'text-highlight' : ''}>3. Meine Produkte</span></h3>
        <p>Sobald wir Ihre Produkte erfasst haben
          wählen Sie die Produkte aus, die in der Slide-Show gezeigt werden sollen (wir empfehlen
          fünf bis maximal zehn Produkte für eine effiziente Käuferansprache).</p>
        {state.error === "crawling_start_failed" && (
        <Alert color="warning">
          Wir können unsere Produktsuche für Ihre angegebene URL <a
            href={state.wslrUrl}
            className="alert-link"
            target="_blank"
            rel="noopener noreferrer"
          >{state.wslrUrl}</a> derzeit nicht starten. Wahrscheinlich läuft bereits eine Suche im Hintergrund. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich an unseren Support. <a
            href="/support"
            className="alert-link"
          >WSLR Hilfe aufrufen</a>
        </Alert>
        )}
        <Button
          color="primary"
          className="mt-3 mb-3"
          onClick={startCrawling}
          disabled={state.step!==3}
        >
          Shop jetzt durchsuchen
        </Button>
        <WSLRProducts 
          message={state.productsMessage}
          products={state.wslrProducts}
          brand={state.wslrBrand}
          loading={state.loading}>
        </WSLRProducts>

        <h3 id="step-6" className="mt-5"><span className={state.step > 5 ? 'text-highlight' : ''}>Fertig</span></h3>
        <p>Nachdem Sie die gewünschten Produkte aus Ihrem Shop ausgewählt haben, 
          nutzen Sie Ihre WSLR-ID für den WSLR-Stick oder die URL für Ihr Smart-TV, Tablet oder 
          ein anderes, internetfähiges Endgerät.</p>
        <WSLRMySlideShowData
          wslrBrand={state.wslrBrand}></WSLRMySlideShowData>
        <p className="mt-5">Weitere Einstellungen können Sie unter <Link to={`/settings`}>Einstellungen</Link> vornehmen.</p>

      </div>

      {state.showResult && (
        <div className="result-block-container">
          <div className="result-block" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <Highlight>
              <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
            </Highlight>
          </div>
        </div>
      )}
     </>
  );
};

export default withAuthenticationRequired(SetupComponent, {
  onRedirecting: () => <Loading />,
});
