import React from "react";

import SupportContact from "../components/SupportContact";
import Faq from "../components/Faq";

const Support = () => (
  <>
    <SupportContact />
    <hr className="mt-5 mb-5" />
    <Faq />
  </>
);

export default Support;
