import React from "react";

const SupportContact = () => (
  <>
    <h1>Support</h1>
    <p>Sie benötigen Hilfe? Wir sind für Sie da!</p>
    <p>Prüfen Sie bitte, ob Ihr Anliegen in unseren FAQ zu finden ist. Sie erreichen uns ansonsten jederzeit unter der Support E-Mail <a href="mailto:support@wslr.io">support@wslr.io</a>.</p>
    <p>Sprechen Sie uns gerne an!</p>
  </>
);

export default SupportContact;
