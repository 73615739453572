import React, { useState } from "react";
import { Button, Alert, UncontrolledAlert } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import WSLRProducts from "../components/WSLR/Products.js";
import WSLRMySlideShowData from "../components/WSLR/MySlideShowData";
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import validator from "validator";

export const SettingsComponent = () => {
  const { apiOrigin } = getConfig();

  const mount = () => {
    console.log('SETTINGS mounted')
    restoreSettings();
  
    const unmount = () => {
      console.log('SETTINGS unmounted')
    }
    return unmount;
  }
  React.useEffect(mount, []);

  const [state, setState] = useState({
    error: null,
    wslrBrand: "",
    wslrUrl: "",
    wslrProducts: {},
    loading: false,
    productsMessage: "",
    initialized: false,
    message: null,
  });

  const {
    getAccessTokenSilently,
    loginWithPopup,
  } = useAuth0();

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const restoreSettings = async () => {
    if(state.initialized) {
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'getBrand'
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        setState({
          ...state,
          wslrBrand: responseData.brand.bid,
          wslrUrl: responseData.brand.crawlUrl,
          initialized: true
        });
      } else {
        setState({
          ...state,
          error: "no_brand_accessible",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const startCrawling = async () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'startCrawling',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        window.wslrCountCrawlStart = 0;
        checkCrawling();
        setState({
          ...state,
          error: null,
          loading: true
        });
      } else {
        setState({
          ...state,
          error: "crawling_start_failed",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const endCrawling = async () => {
    try {
      const token = await getAccessTokenSilently();
      await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'endCrawling',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
    } catch (error) {}
  };

  window.wslrCrawlCheckTimeout = null;
  window.wslrCountCrawlStart = 0;
  const checkCrawling = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'checkCrawling',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        if(responseData.crawlData.status !== 'finished') {
          switch(responseData.crawlData.status) {
            case 'start':
              window.wslrCountCrawlStart++;
              // if start exists for >10 sec, an error occured; then:
              if(window.wslrCountCrawlStart>10) {
                setState({
                  ...state,
                  error: "crawling_url_wrong",
                  loading: false,
                });
                endCrawling();
                return;
              }
              break;
            case 'running':
              // update counter
              var msg = "Wir haben bislang " + responseData.crawlData.pages + " Seiten durchsucht und " + responseData.crawlData.products + " Produkte gefunden...";
              setState({
                ...state,
                productsMessage: msg,
                loading: true,
                error: null,
              });
              break;
            case 'error':
              setState({
                ...state,
                error: "crawling_url_wrong",
                loading: false,
              });
              endCrawling();
              return;
            default:
              return;
          }
          clearTimeout(window.wslrCrawlCheckTimeout);
          window.wslrCrawlCheckTimeout = setTimeout(checkCrawling, 1000);
        } else {
          // finished crawling
          endCrawling();
          if(responseData.crawlData.pages === "0") {
            // nothing found
            setState({
              ...state,
              error: "crawling_url_wrong",
              loading: false,
            });
            return;
          }
          if(responseData.crawlData.products === "0") {
            // no products found
            setState({
              ...state,
              error: "crawling_url_noproducts",
              loading: false,
            });
            return;
          }
          // success
          setState({
            ...state,
            error: null,
            loading: false,
          });
          loadProducts();
        }
      } else {
        setState({
          ...state,
          error: "crawling_check_failed",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const loadProducts = async () => {
    if(!state.initialized) {
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'loadProducts',
          brand: state.wslrBrand,
          url: state.wslrUrl
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        setState({
          ...state,
          wslrProducts: responseData.products,
          error: null
        });
      } else {
        setState({
          ...state,
          error: "error_loading_products",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };
  React.useEffect(() => {
    loadProducts();
    return () => {};
  }, [state.initialized]); // eslint-disable-line react-hooks/exhaustive-deps

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  /*
  *   Clean Products
  */
  const [openCleanProductsAlert, setOpenCleanProductsAlert] = React.useState(false);
  const handleCloseCleanProductsAlert = () => {
    setOpenCleanProductsAlert(false);
  }
  const cleanProducts = async () => {
    setOpenCleanProductsAlert(false);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'cleanProducts',
          brand: state.wslrBrand
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        setState({
          ...state,
          error: null,
          message: "cleanup_start",
        });
      } else {
        setState({
          ...state,
          error: "cleanup_start_failed",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }


  /*
  *   Add single Products
  */
  const [singleUrlValue, setSingleUrlValue] = React.useState('');
  const [openSingleProductsModal, setOpenSingleProductsModal] = React.useState(false);
  const handleCloseSingleProductsModal = () => {
    setOpenSingleProductsModal(false);
  }
  const addSingleProducts = async () => {
    var cUrl = document.getElementById('singleProductUrl').value;
    console.log(cUrl);

    if(!validator.isURL(cUrl, {require_protocol: true})) {
      return;
    }

    setOpenSingleProductsModal(false);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          action: 'crawlSinglePage',
          brand: state.wslrBrand,
          url: cUrl
        })
      });
      const responseData = await response.json();

      if(responseData.message.indexOf("Success")>-1) {
        setState({
          ...state,
          error: null
        });
        setTimeout(loadProducts, 1000);
      } else {
        setState({
          ...state,
          error: "crawling_single_start_failed",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  return (
    <>
      <div className="mb-5">
        {state.error === "login_required" && (
          <Alert color="danger">
            Sie müssen sich{" "}
            <a
              href="#/"
              className="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              erneut anmelden.
            </a>
          </Alert>
        )}
        {state.error === "crawling_start_failed" && (
          <Alert color="warning">
            Wir können unsere Produktsuche für Ihre angegebene URL <a
              href={state.wslrUrl}
              className="alert-link"
              target="_blank"
              rel="noopener noreferrer"
            >{state.wslrUrl}</a> derzeit nicht starten. Wahrscheinlich läuft bereits eine Suche im Hintergrund. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich an unseren Support. <a
              href="/support"
              className="alert-link"
            >WSLR Hilfe aufrufen</a>
          </Alert>
        )}
        {state.error === "crawling_single_start_failed" && (
          <Alert color="warning">
            Wir können unsere Produktsuche für Ihre angegebene URL derzeit nicht starten. Bitte prüfen Sie, ob die eingegebene URL korrekt ist oder wenden Sie sich an unseren Support. <a
              href="/support"
              className="alert-link"
            >WSLR Hilfe aufrufen</a>
          </Alert>
        )}
        {state.message === "cleanup_start" && (
          <UncontrolledAlert color="success">
            Die Bereinigung der Produkte wurde gestartet.
          </UncontrolledAlert>
        )}
        {state.message === "cleanup_end" && (
          <UncontrolledAlert color="success">
            Die Bereinigung der Produkte wurde erfolgreich abgeschlossen.
          </UncontrolledAlert>
        )}

        <h1>Einstellungen</h1>
        <p className="lead">
          Passen Sie Ihre WindowSeller Slideshow an.
        </p>
        <WSLRMySlideShowData
          wslrBrand={state.wslrBrand}></WSLRMySlideShowData>


        <h3 id="products" className="mt-5">Meine Produkte</h3>
        <p>
          Ändern Sie die Produkte, die angezeigt werden sollen oder lassen Sie Ihren Online-Shop erneut durchsuchen, wenn Sie dort Produktänderungen durchgeführt haben.
        </p>
        <WSLRProducts 
          message={state.productsMessage}
          products={state.wslrProducts}
          brand={state.wslrBrand}
          loading={state.loading}>
        </WSLRProducts>
        <Button
          color="primary"
          className="mt-5"
          onClick={startCrawling}
          disabled={state.loading || !state.initialized}
        >
          Shop erneut durchsuchen
        </Button>
        <Button
          color="secondary"
          className="mt-5 ml-3"
          onClick={() => {  setOpenCleanProductsAlert(true); }}
          disabled={state.loading || !state.initialized}
        >
          Produkte bereinigen
        </Button>
        <Button
          color="secondary"
          className="mt-5 ml-3"
          onClick={() => {  setOpenSingleProductsModal(true); }}
          disabled={state.loading || !state.initialized}
        >
          Einzelprodukt hinzufügen
        </Button>
      </div>
      <Dialog
        open={openCleanProductsAlert}
        onClose={handleCloseCleanProductsAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Produkte bereinigen?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Alle bisher gecrawlten Produkte werden überprüft und nicht mehr gültige Links werden gelöscht. Nach dem Starten der Bereinigung läuft diese im Hintergrund, Sie können in der Zeit weiterarbeiten.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleCloseCleanProductsAlert} color="secondary" >
            Abbrechen
          </MuiButton>
          <MuiButton onClick={cleanProducts} color="primary" >
            Bereinigung starten
          </MuiButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSingleProductsModal}
        onClose={handleCloseSingleProductsModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Einzelprodukte hinzufügen"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Fügen Sie einzelne Produkte anhand ihrer Produkt-URL hinzu. Kopieren Sie dazu am besten die URL der gewünschten Produkt-Detailseite aus Ihrem Browser in das Eingabefeld.<br />
            <TextField
              className="mt-3 mb-2"
              id="singleProductUrl"
              fullWidth={true}
              placeholder="Produkt-Detailseite URL (https://www...)"
              label="Produkt URL" 
              variant="outlined"
              onChange={() => { setSingleUrlValue(document.getElementById('singleProductUrl').value) }}
              error={singleUrlValue !== '' && !validator.isURL(singleUrlValue, {require_protocol: true})}
            ></TextField>
            <br />
            {(singleUrlValue !== '' && !validator.isURL(singleUrlValue, {require_protocol: true})) && <p className="hint error"><u>Achtung:</u> Bitte verwenden Sie eine gültige URL mit Protokoll (z.B. https://)</p>}
            {(singleUrlValue === '' || validator.isURL(singleUrlValue, {require_protocol: true})) && <p className="hint"><u>Tipp:</u> Sie können dabei auch eine andere als Ihre Haupt-URL verwenden!</p>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleCloseSingleProductsModal} color="secondary" >
            Abbrechen
          </MuiButton>
          <MuiButton onClick={addSingleProducts} color="primary" >
            Hinzufügen
          </MuiButton>
        </DialogActions>
      </Dialog>
     </>
  );
};

export default withAuthenticationRequired(SettingsComponent, {
  onRedirecting: () => <Loading />,
});


