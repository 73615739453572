import React from "react";

const WSLRMySlideShowData = (props) => (
  <div className="wslr-myslideshowdata">
    <p className="mb-1"><span className="text-highlight"><b>WSLR-ID: </b> {props.wslrBrand}</span></p>
    <span className="hint">Diese ID kann in der App auf Ihrem WSLR-Stick eingegeben werden, um Ihre Slideshow zu starten</span>
    <p className="mb-1 mt-4"><span className="text-highlight"><b>WSLR-URL: </b> <a
            href={"https://frontend.wslr.io/?brand=" + props.wslrBrand}
            className="text-highlight"
            target="_blank"
            rel="noopener noreferrer"
          >https://frontend.wslr.io/?brand={props.wslrBrand}</a></span></p>
    <span className="hint">Diese URL können Sie auf Ihrem Smart-TV, Rechner, Tablet oder anderen, internefähigen Endgeräten verwenden, um Ihre Slideshow zu starten.</span>
  </div>
);

export default WSLRMySlideShowData;
