import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Alert } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";


export default function PayPal(props) {
    const { apiOrigin } = getConfig();

    const [state, setState] = useState({
        wslrBrand: props.brand,
        error: null,
      });

    const updateProps = () => {
        if(props.brand && props.brand !== '') {
            setState({
                ...state,
                wslrBrand: props.brand,
            });
        }
    }
    React.useEffect(updateProps, [props]);
    
    const { 
        getAccessTokenSilently, 
    } = useAuth0();

    const initialOptions = {
        "client-id": "AY67_LS2O_oYwgUjxsFj_Cvok3s-vlWMRoQM-vfOyqWm7MzOAdEAx-zMIasozSuxgVWaoIBS8ybEDDfL", // LIVE
        // "client-id": "AS7ELPIZMqtc5YpcS58QoS2JB2eRXerTkynqncqmVxy0Z0nq4cLtFXQgwdoZgTjHduiXSTuhua_GjXM8", // SANDBOX
        "intent": "subscription",
        "vault": "true",
    };
    
    const ppCreateSubscription = (data, actions) => {
        return actions.subscription.create({
            plan_id: 'P-780365412N9386407MB6XZZA' // LIVE
            // plan_id: 'P-6GU370631Y485980NMB6ZGWQ' // SANDBOX
        });
    };
    
    const ppOnApprove = async (data, actions) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${apiOrigin}`, {
                headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    action: 'setPaymentId',
                    brand: state.wslrBrand,
                    paymentId: data.subscriptionID
                })
            });
            const responseData = await response.json();
            console.log(responseData);
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } catch (error) {
            console.log("CATCHED ERROR:", error);
            setState({
                ...state,
                error: error.error,
            });
        };
    };
    
    const ppOnError = (err) => {
        console.log("Error", err);
        setState({
            ...state,
            error: 'paypalerror'
        });
    };

    return (
        <>
        { state.error === 'paypalerror' && (
            <Alert color="danger">
                Fehler beim Abschluss des Abos bei PayPal. Bitte versuchen Sie es erneut.
            </Alert>
        )}
        { state.wslrBrand !== '' && (
            <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                createSubscription={ppCreateSubscription} 
                onApprove={ppOnApprove}
                catchError={ppOnError}
                onError={ppOnError}
                onCancel={ppOnError}
                />
            </PayPalScriptProvider>
        )}
        </>
    );
}