const contentData = [
  {
    title: "Richten Sie Ihren WindowSeller ein.",
    link: "/setup",
    description:
      "Erstellen Sie einen WindowSeller Account und legen Sie sofort los! Verbinden Sie Ihren Online-Shop mit Ihrem WindowSeller Account und wählen Sie die Produkte aus, die Sie im Schaufenster präsentieren möchten."
  },
  {
    title: "Zeigen Sie, was Sie haben.",
    link: "/settings",
    description:
      "Am besten funktionieren WindowSeller Slideshows mit fünf bis zehn ausgewählten Produkten, die Spontanshopper auf Ihren Online-Shop aufmerksam machen. Verlieren Sie keine Kunden mehr an große Online-Händler!"
  },
  {
    title: "Erweitern Sie Ihr Schaufenster.",
    link: "https://www.windowseller.io/best-practice",
    description:
      "Nutzen Sie Tablets, Monitore, Smart-TVs oder professionelle Signage-Hardware für Ihren WindowSeller. Mit oder ohne den WindowSeller-Stick. Sie haben die freie Entscheidung, welche Lösung am besten zu Ihrem Schaufenster und in ihr Budget passt."
  },
  {
    title: "24/7 Shopping am Point of Sale.",
    link: "https://www.windowseller.io/best-practice",
    description:
      "Immer offen, immer erreichbar. Erweitern Sie Ihr Schaufenster um Ihren Online-Kanal und nutzen Sie das potential der Spontankäufe rund um die Uhr mit Ihrem WindowSeller. Viel Erfolg!"
  }
];

export default contentData;
